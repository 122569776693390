import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUserLevelClient } from '@interfaces/user-level';
import { User } from '@models/user';
import { UserSyncResponse } from '@models/user-sync-response';
import { UserRequestModel } from '@models/UserRequestModel';
import { AppConfigProvider } from '@root/app/app.config.provider';
import { backoff } from '@shared/backoff';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, debounceTime, map } from 'rxjs/operators';
import { OpenIdConnectService } from '../auth/open-id-connect.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  private usersUrl: string; // Get Active Users
  public userLevels = {};

  private userList = new BehaviorSubject<User[]>([]);
  public userList$ = this.userList.asObservable();

  constructor(private http: HttpClient, private auth: OpenIdConnectService, private appConfig: AppConfigProvider) {
    this.appConfig.environment.subscribe(env => {
      this.usersUrl = `${env.API.USERAPI}/api/users`; // Get Active Users
    });
  }

  public getUserLevels(): Observable<IUserLevelClient[]> {
    return this.http.get<IUserLevelClient[]>(`${this.usersUrl}/levels`).pipe(
      backoff(3, 250),
      map((userLevelsClients: IUserLevelClient[]) => {
        userLevelsClients.forEach(userLevelsClient => {
          const userLevelClient: IUserLevelClient = {
            is_bi_enabled: userLevelsClient.is_bi_enabled,
            level_id: userLevelsClient.level_id,
            level_text: userLevelsClient.level_text,
            client_id: userLevelsClient.client_id,
            client_name: userLevelsClient.client_name,
            module_id: userLevelsClient.module_id,
            module_name: userLevelsClient.module_name,
            display_name: userLevelsClient.display_name
          };
          this.userLevels[userLevelsClient.level_id] = userLevelClient;
        });

        return userLevelsClients;
      }),
      catchError(err => of([]))
    ) as Observable<IUserLevelClient[]>;
  }

  /**
   *
   * @return E.g. 'Liquidity Administrator, Liquidity User'
   */
  public getUserLevelNames(userLevelIds: number[]) {
    const userLevelNames = [];
    userLevelIds.forEach(userLevelId => {
      if (this.userLevels[userLevelId]) {
        userLevelNames.push(this.userLevels[userLevelId].level_text);
      }
    });
    return userLevelNames.join(', ');
  }

  public getUserLevelClientByName(userLevelName: string): IUserLevelClient {
    const key = Number(Object.keys(this.userLevels).find(level_id => this.userLevels[level_id].level_text === userLevelName));
    return key ? this.userLevels[key] : null;
  }

  public getUserLevelClientById(userLevelId: number): IUserLevelClient {
    const key = Number(Object.keys(this.userLevels).find(level_id => this.userLevels[level_id].level_id === userLevelId));
    return key ? this.userLevels[key] : null;
  }

  public getAllUsers(): Observable<User[]> {
    const requestModel = new UserRequestModel();
    requestModel.id = this.auth.userId; // Id of the user that has request the action
    requestModel.Model.customer_id = this.auth.customerId;

    return this.http.post<User[]>(`${this.usersUrl}/all`, requestModel).pipe(
      map(users => {
        this.userList.next(users);
        return users;
      })
    );
  }

  public searchUsersByFieldValue(fieldName: string, fieldValue: string): Observable<User[]> {
    const url = `${this.usersUrl}/search`;
    return this.searchUsersByField(fieldName, fieldValue, url);
  }
  public searchUsersByFieldIgnoreCustomer(fieldName: string, fieldValue: string): Observable<User[]> {
    const url = `${this.usersUrl}/search/ic`;
    return this.searchUsersByField(fieldName, fieldValue, url);
  }

  private searchUsersByField(fieldName: string, fieldValue: string, url: string): Observable<User[]> {
    const requestModel = new UserRequestModel();
    requestModel.id = this.auth.userId; // Id of the user that has request the action
    requestModel.Model.customer_id = this.auth.customerId;
    requestModel.Query = {
      fieldName: fieldName.trim(),
      fieldValue: fieldValue.trim()
    };

    return this.http.post<User[]>(url, requestModel).pipe(catchError(err => of([])));
  }

  public addUser(user: User): Observable<User> {
    return this.http.post<User>(`${this.usersUrl}/add`, user);
  }

  public updateUser(user: User): Observable<any> {
    return this.http.put(this.usersUrl, user);
  }

  public deleteUser(user: User) {
    return this.http.delete<User>(`${this.usersUrl}/${user.user_id}`).pipe(debounceTime(200));
  }

  public undeleteUser(user: User) {
    return this.http.patch<User>(`${this.usersUrl}/${user.user_id}`, null).pipe(debounceTime(200));
  }

  public synchronizeBiUser(user: User): Promise<UserSyncResponse> {
    return this.http.post<UserSyncResponse>(`${this.usersUrl}/sync`, user).toPromise();
  }

  getUserById(id: number): Observable<User> {
    const requestModel = new UserRequestModel();
    requestModel.Model.customer_id = this.auth.customerId;

    return this.http.post<User>(`${this.usersUrl}/${id}`, requestModel).pipe(
      backoff(3, 250),
      catchError(err => of(null))
    ) as Observable<User>;
  }
}
