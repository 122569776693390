import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { OpenIdConnectService } from '@services/auth/open-id-connect.service';

@Component({
  selector: 'app-signin-oidc',
  templateUrl: './signin-oidc.component.html',
  styleUrls: ['./signin-oidc.component.scss']
})
export class SigninOidcComponent implements OnInit {
  constructor(private openIdConnectService: OpenIdConnectService, private router: Router) {}

  ngOnInit() {
    this.openIdConnectService.userLoaded$.subscribe(userLoaded => {
      if (userLoaded) {
        this.router.navigate(['./']);
      } else {
        if (!environment.production) {
          console.warn("An error happened: user wasn't loaded");
        }
      }
    });
    this.openIdConnectService.handleCallBack();
  }
}
