// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown_title {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 20px;
  padding: 5px 0;
  margin-bottom: 10px;
}

ul#application_list {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
}
ul#application_list > li {
  float: left;
  width: 33.3333333333%;
  text-align: center;
}

.clearfix::after {
  clear: both;
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/core/application-switcher/application-switcher.component.scss","webpack://./../../../Empyrean%20User%20Manager/Web/EmpyreanUserManager/src/app/core/application-switcher/application-switcher.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;EACA,mBAAA;ACCF;;ADEA;EACE,WAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;EACA,gBAAA;ACCF;ADCE;EACE,WAAA;EACA,qBAAA;EACA,kBAAA;ACCJ;;ADGA;EACE,WAAA;EACA,WAAA;EACA,cAAA;EACA,SAAA;EACA,kBAAA;ACAF","sourcesContent":[".dropdown_title {\r\n  font-size: 1.2rem;\r\n  font-weight: 300;\r\n  line-height: 20px;\r\n  padding: 5px 0;\r\n  margin-bottom: 10px;\r\n}\r\n\r\nul#application_list {\r\n  width: 100%;\r\n  float: left;\r\n  margin: 0;\r\n  padding: 0;\r\n  list-style: none;\r\n\r\n  > li {\r\n    float: left;\r\n    width: calc(100% / 3);\r\n    text-align: center;\r\n  }\r\n}\r\n\r\n.clearfix::after {\r\n  clear: both;\r\n  content: '';\r\n  display: block;\r\n  height: 0;\r\n  visibility: hidden;\r\n}\r\n",".dropdown_title {\n  font-size: 1.2rem;\n  font-weight: 300;\n  line-height: 20px;\n  padding: 5px 0;\n  margin-bottom: 10px;\n}\n\nul#application_list {\n  width: 100%;\n  float: left;\n  margin: 0;\n  padding: 0;\n  list-style: none;\n}\nul#application_list > li {\n  float: left;\n  width: 33.3333333333%;\n  text-align: center;\n}\n\n.clearfix::after {\n  clear: both;\n  content: \"\";\n  display: block;\n  height: 0;\n  visibility: hidden;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
