// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul.user-details-list {
  padding: 0;
  list-style-type: none;
}
ul.user-details-list .user-details-list-item {
  padding: 0.25rem 1rem;
}

#small-version-display {
  text-align: right;
  font-size: 0.7rem;
}

.cursor-pointer {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/core/header/header.component.scss","webpack://./../../../Empyrean%20User%20Manager/Web/EmpyreanUserManager/src/app/core/header/header.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,qBAAA;ACCF;ADCE;EACE,qBAAA;ACCJ;;ADGA;EACE,iBAAA;EACA,iBAAA;ACAF;;ADGA;EACE,eAAA;ACAF","sourcesContent":["ul.user-details-list {\r\n  padding: 0;\r\n  list-style-type: none;\r\n\r\n  .user-details-list-item {\r\n    padding: 0.25rem 1rem;\r\n  }\r\n}\r\n\r\n#small-version-display {\r\n  text-align: right;\r\n  font-size: 0.7rem;\r\n}\r\n\r\n.cursor-pointer {\r\n  cursor: pointer;\r\n}\r\n","ul.user-details-list {\n  padding: 0;\n  list-style-type: none;\n}\nul.user-details-list .user-details-list-item {\n  padding: 0.25rem 1rem;\n}\n\n#small-version-display {\n  text-align: right;\n  font-size: 0.7rem;\n}\n\n.cursor-pointer {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
