import { Component, Input, OnInit } from '@angular/core';

type Speed = 'normal' | 'slow' | 'slower';

@Component({
  selector: 'app-empyrean-loader',
  templateUrl: './empyrean-loader.component.html',
  styleUrls: ['./empyrean-loader.component.scss'],
})
export class EmpyreanLoaderComponent implements OnInit {
  @Input() loading = true;
  @Input() color = '#4e8cb7';
  @Input() speed: Speed = 'normal';
  @Input() plain = false;
  @Input() relative = false;
  @Input() width = 50;
  @Input() numBars = 9;
  /**
   * A text string to be displayed over the Empyrean logo. Can be empty for no messsage
   */
  @Input() message = null;
  /**
   * Displays the loader on an overlay box instead of transparent.
   */
  @Input() boxed = false;

  public bars: number[] = [];
  public barHeight = 0;

  constructor() {}

  ngOnInit(): void {
    this.bars = new Array(this.numBars).fill(null).map((e, i) => (e = this.getIdx(i + 1)));
    this.barHeight = Number((this.height / Math.ceil(this.numBars / 2)).toFixed(2));
  }

  private readonly getIdx = (n: number) => {
    const mid = Math.ceil(this.numBars / 2);
    return n === mid ? 0 : n > mid ? mid - ((this.numBars + 1 - n) % mid) : mid - (n % mid);
  };

  get height() {
    return Number(Math.floor(this.width / 2.25).toFixed(2));
  }

  get gridGap() {
    return Math.ceil(this.width / 90);
  }

  getHeight = (index: number) => this.height - this.bars[index] * this.barHeight;

  getSpeed(): string {
    let s = '2.4';
    switch (this.speed) {
      case 'normal':
        break;
      case 'slow':
        s = '4.8';
        break;
      case 'slower':
        s = '9.6';
        break;

      default:
        s = '1.2';
        break;
    }
    return s;
  }
}
