import { OpenIdConnectService } from '@services/auth/open-id-connect.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redirect-silent-renew',
  templateUrl: './redirect-silent-renew.component.html',
  styleUrls: ['./redirect-silent-renew.component.scss']
})
export class RedirectSilentRenewComponent implements OnInit {
  constructor(private openIdConnectService: OpenIdConnectService) {}

  ngOnInit() {
    this.openIdConnectService.handleSilentCallback();
  }
}
