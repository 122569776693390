import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@services/settings/settings.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss']
})
export class TimeoutComponent implements OnInit {
  constructor(public settings: SettingsService, private _location: Location, private router: Router) {}

  ngOnInit() {}

  public goBack() {
    this.router.navigate(['']);
  }
}
