import { OpenIdConnectService } from './../auth/open-id-connect.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class RelogOn401Interceptor implements HttpInterceptor {
  constructor(private openIdConnectService: OpenIdConnectService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // map(
      //   (event: HttpEvent<any>) => {
      //   if (event instanceof HttpResponse) {
      //     console.log('response -> ', event);
      //   }
      //   return event;
      // }),
      catchError((error: HttpErrorResponse) => {
        const data = {
          reason: error && error.error && error.error.reason ? error.error.reason : '',
          status: error.status
        };
        // Force SignIn on 401 response from API
        if (data.status === 401 && this.openIdConnectService.userAvailable) {
          // this.openIdConnectService.triggerSignIn();
          console.error('Redirecting to 401 due to Unauthorized response', data.reason, this.openIdConnectService.user);
          window.location.href = '/401';
        }
        return throwError(error);
      })
    );
  }
}
