/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AddAuthorizationHeaderInterceptor } from './add-authorization-header-interceptor';
import { LoggingInterceptor } from './logging-interceptor';
import { RelogOn401Interceptor } from './relog-on-401-interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: LoggingInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: AddAuthorizationHeaderInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: RelogOn401Interceptor, multi: true },
];
