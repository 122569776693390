import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root' // Provides the service at the root level.
})
export class ToastService {
  constructor(private toastrService: ToastrService) { }

  showToast(body: string, title: string, type = 'success', toastrOptions: any) {

    if (toastrOptions == null) {
      toastrOptions = { closeButton: true };
    }

    switch (type) {
      case 'success':
        this.toastrService.success(body, title, toastrOptions);
        break;
      case 'info':
        this.toastrService.info(body, title, toastrOptions);
        break;
      case 'warning':
        this.toastrService.warning(body, title, toastrOptions);
        break;
      case 'error':
        this.toastrService.error(body, title, toastrOptions);
        break;
      default:
        this.toastrService.success(body, title, toastrOptions); // Default to success if type is unknown.
        break;
    }
  }
}
