import { UserStatus } from './user-status';

export class UserListFilter {
  username: string = '';
  first_name: string = '';
  last_name: string = '';
  email: string = '';
  user_level: string = '';
  all: string = '';
  record_status: UserStatus = UserStatus.A;
}

export enum ActiveUsersToggleFilter {
  All = 'All',
  A = 'A',
  D = 'D',
  Disabled = 'Disabled',
}
