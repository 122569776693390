import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { IGlobalParam } from '@interfaces/global-param';
import { GlobalParamsService } from '@services/global-params/global-params.service';
import { clone } from '@shared/arrays';
import { ToasterService } from 'angular2-toaster';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-global-settings',
  templateUrl: './global-settings.component.html',
  styleUrls: ['./global-settings.component.scss'],
})
export class GlobalSettingsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  public params: IGlobalParam[] = [];
  public storedParams: IGlobalParam[] = [];
  public isSaving = false;
  public isLoading = false;
  public maximized = false;
  public settingsChanged = false;

  get areValidParams() {
    return this.params.every((p) => p.Valid);
  }

  constructor(
    private readonly globalParams: GlobalParamsService,
    private readonly dialogRef: MatDialogRef<GlobalSettingsComponent>,
    private readonly toaster: ToasterService
  ) {}

  ngOnInit() {
    this.isLoading = true;
    const globalParams$ = this.globalParams.getParams().subscribe(
      (params: any[]) => {
        this.params = params;
        this.storedParams = clone(params);
        this.isLoading = false;
      },
      (err) => {
        this.toaster.popAsync({ type: 'error', title: '', body: 'Unable to load Global Settings' });
        this.isLoading = false;
      }
    );
    this.subscriptions.add(globalParams$);
  }

  onParamValueChanged(param: IGlobalParam) {
    param.Modified = true;
    this.settingsChanged = this.checkSettingsChanged();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  private checkSettingsChanged() {
    // If params are different to storedParams
    this.checkValidSettings();
    for (let i = 0; i < this.storedParams.length; i++) {
      const param = this.params[i];
      const storedParam = this.storedParams[i];

      if (!param.ReadOnly) {
        if (param.value !== storedParam.value) {
          return true;
        }
      }
    }

    return false;
  }

  public saveGlobalSettings() {
    this.isSaving = true;
    this.globalParams.updateUserSettings(this.params).subscribe(
      (res) => {
        this.params = res;
        this.storedParams = clone(res);
        this.settingsChanged = false;
        this.isSaving = false;
        this.toaster.popAsync({ type: 'success', title: '', body: 'Global Settings successfully updated' });
      },
      (err) => {
        this.isSaving = false;
        this.toaster.popAsync({ type: 'error', title: '', body: 'Unable to update Global Settings' });
      }
    );
  }

  private checkValidSettings() {
    const idle_time = this.params.find((p) => p.name === 'MAX_IDLE_TIME');
    const idle_value = parseInt(idle_time.value, 10);
    idle_time.Valid = Number(idle_value) === idle_value && idle_value >= 5;
  }

  public getUnits(param) {
    const units = {
      MAX_IDLE_TIME: 'mins',
    };

    return units[param.ParamName] || '';
  }

  public close(event) {
    this.dialogRef.close();
  }
}
