import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmpyreanApplicationName, GroupedLevels, UserLevelApplicationLinks } from '@interfaces/user-level';
import { IAddUserDialogParams } from '@models/add-user-dialog-params';
import { User } from '@models/user';
import { EMPYREAN_BI_MODULE_ID, IUserLevelClient, UserLevel } from '@models/user-level';
import { UserSyncResponse } from '@models/user-sync-response';
import { OpenIdConnectService } from '@services/auth/open-id-connect.service';
import { UserService } from '@services/user/user.service';
import { enumerateString, toTitleCase } from '@shared/strings';
import { openDialogConfirmation } from '@shared/swal-dialogs';
import { TrimFormControl } from '@shared/trim-form-control';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ToastService } from '@services/toast-service/toast.service';

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss']
})
export class AddUserDialogComponent implements OnInit {
  @ViewChild('usernameOrigin', { static: true }) usernameOrigin;
  @ViewChild('emailOrigin', { static: true }) emailOrigin;

  private biEnabledClients: Map<string, boolean> = new Map();
  private debounceTime = 500;

  public autoFillUser: User = null;
  public groupedLevels: GroupedLevels[] = [];
  public initialUserIsEnabled: boolean;
  public isBiGroupDisabled = false;
  public isSaving: boolean = false;
  public isSynching: boolean = false;
  public modalUserForm: FormGroup;
  public overlayOrigin;
  public previousEmail: string;
  public previousUsername: string;
  public selectedUserLevels: IUserLevelClient[] = [];
  public usingPrefilledInputs: User = null;

  get showSync(): boolean {
    return this.data && this.data.user && this.data.user.is_bi_user_sync === false;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IAddUserDialogParams,
    private readonly dialogRef: MatDialogRef<AddUserDialogComponent>,
    private fb: FormBuilder,
    private readonly userService: UserService,
    private toastService: ToastService,
    private readonly oidcService: OpenIdConnectService
  ) {
    this.initForm();
    this.groupedLevels = this.getGroupedLevels(this.data.levels);
  }

  public ngOnInit() {
    this.biEnabledClients = new Map(this.data.levels.map(l => [l.module_name, l.is_bi_enabled]));
    this.biEnabledClients.set('BI', false);
  }

  public closeDialog() {
    this.dialogRef.close();
  }

  getErrorMessage(fieldName: string) {
    const field = this.modalUserForm.get(fieldName);

    if (field.hasError('required'))
      return fieldName === 'user_levels' ? 'At least 1 user level is required' : `${toTitleCase(fieldName)} is required`;
    if (field.hasError('minlength')) return `Min length: 3 characters`;
    if (field.hasError('maxlength')) return `Max length: 50 characters`;
    if (field.hasError('username')) return `Username already in use`;
    if (field.hasError('pattern')) return `Username cannot contain spaces`;
    if (field.hasError('emailTaken')) return `Email address already in use`;
    if (field.hasError('usernameTaken')) return `Username already in the database`;
    if (field.hasError('email')) {
      return `Invalid email address`;
    }
  }
  getSuccessMessage(fieldName: string) {
    if (fieldName === 'email' && !this.modalUserForm.hasError('uniqueEmail')) return `Email available`;
    if (fieldName === 'username' && !this.modalUserForm.hasError('uniqueUsername') && !this.usingPrefilledInputs)
      return `Username available`;
  }
  public get isFormValid() {
    return this.modalUserForm.valid && !this.modalUserForm.pristine;
  }

  synchronizeBiUser = async () => {
    this.isSynching = true;

    setTimeout(async () => {
      const user = { ...this.data.user, ...this.modalUserForm.value };

      user.user_levels = this.selectedUserLevels.map(selected_level => selected_level.level_id);

      const syncResponse: UserSyncResponse = await this.userService.synchronizeBiUser(user).catch(err => {
        console.warn(err);
        return null;
      });

      if (!syncResponse || syncResponse.isSync === false) {
        this.toastService.showToast('An error occurred when synchronizing the user', 'Error', 'error', null)
      } else {
        if (syncResponse.isSync) {
          this.data.user.is_bi_user_sync = syncResponse.isSync;
        }
        this.toastService.showToast('User synchronized successfully', 'Success', 'success', null)
      }

      this.isSynching = false;
    }, 50);
  };

  async onSaveNewUser(): Promise<void> {
    this.isSaving = true;

    if (this.usingPrefilledInputs) {
      await this.onSaveEditUser(this.usingPrefilledInputs);
    } else {
      const newUser = { ...new User(), ...this.modalUserForm.value };

      newUser.user_levels = this.selectedUserLevels.map(selected_level => selected_level.level_id);

      await this.userService
        .addUser(newUser)
        .toPromise()
        .then(async res => {
          this.toastService.showToast(`User ${newUser.username} created successfully`, 'Success', 'success', null)
        })
        .catch(async err => {
          this.toastService.showToast(`An error occurred when creating user ${newUser.username || this.data.user.username}`, 'Error', "error", null);
          console.warn(err);
        })
        .finally(async () => {
          this.isSaving = false;
          this.dialogRef.close();
          await this.userService.getAllUsers().toPromise();
        });
    }
  }

  async onSaveEditUser(userData = this.data.user) {
    this.isSaving = true;

    const updatedUser = { ...userData, ...this.modalUserForm.value };

    updatedUser.user_levels = this.selectedUserLevels.map(selected_level => selected_level.level_id);

    if (updatedUser.enabled === false && this.data.user && this.data.user.enabled === true) {
      const title = 'Confirm Disabling the User';
      const text = `Disabling the user ${updatedUser.username} will result in the removal of all the changes by the user that have not been saved yet.`;
      const confirmed = await openDialogConfirmation(title, text);

      if (!confirmed.value) {
        this.dialogRef.close();
        return;
      }
    }

    await this.userService
      .updateUser(updatedUser)
      .toPromise()
      .then(async resultUser => {
        this.toastService.showToast(`User ${updatedUser.username} has been updated successfully`, 'Success', 'success', null);
      })
      .catch(async err => {
        this.toastService.showToast(`An error occurred when updating user ${updatedUser.username || this.data.user.username}`, 'Error', "error", null);
        console.warn(err);
      })
      .finally(async () => {
        this.isSaving = false;
        this.dialogRef.close();
        await this.userService.getAllUsers().toPromise();
      });
  }

  get isEditing() {
    return this.data.isEditing || false;
  }

  private initForm() {
    const defaultUser = this.data.user || new User();
    this.previousEmail = defaultUser.email;
    this.previousUsername = defaultUser.username;
    this.initialUserIsEnabled = defaultUser.enabled;

    if (defaultUser) {
      this.selectedUserLevels = [...defaultUser.user_levels.map(ul => this.userService.getUserLevelClientById(ul))];
    }

    this.modalUserForm = this.fb.group({
      first_name: new TrimFormControl(defaultUser.first_name, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      last_name: new TrimFormControl(defaultUser.last_name, [Validators.required, Validators.minLength(1), Validators.maxLength(100)]),
      username: new TrimFormControl(
        // Usernames can contain any character but spaces.
        defaultUser.username,
        [Validators.required, Validators.minLength(3), Validators.maxLength(50), Validators.pattern(/^[^\s]+$/)],
        [this.usernameUniquenessValidator()]
      ),
      email: new TrimFormControl(
        defaultUser.email,
        [Validators.required, Validators.email, Validators.minLength(3), Validators.maxLength(100)],
        [this.emailUniquenessValidator()]
      ),
      user_levels: [this.selectedUserLevels, [Validators.required]]
      // enabled: [defaultUser.enabled, [Validators.required]]
    });
  }

  public isSelectionHidden(userLevel: UserLevel): boolean {
    const isHRAdministrator = this.data.currentUserLevelList.includes(UserLevel.HRSystemAdministrator);
    const isSystemAdministrator = this.data.currentUserLevelList.includes(UserLevel.SystemAdministrator);
    const isPayrollLevel = UserLevelApplicationLinks[EmpyreanApplicationName.Payroll].includes(userLevel);

    // HR Admins cannot change user levels outside Payroll, Only HR Admins can change Payroll Levels
    if (
      (userLevel === UserLevel.HRSystemAdministrator && !isHRAdministrator) ||
      (isPayrollLevel && !isHRAdministrator) ||
      (!isPayrollLevel && userLevel !== UserLevel.HRSystemAdministrator && !isSystemAdministrator) ||
      (userLevel == UserLevel.PayrollReader) || (userLevel == UserLevel.PlanningReader)
    ) {
      return true;
    }

    return false;
  }

  private getGroupedLevels(levels: IUserLevelClient[]): GroupedLevels[] {
    let groups = {};
    for (const level of levels) {
      // Hide elements in select dropdown depending on permissions of logged in user.
      const isHidden = this.isSelectionHidden(level.level_id);

      if (!isHidden) {
        if (groups[level.module_name]) {
          groups[level.module_name].push(level);
        } else {
          groups[level.module_name] = [level];
        }
      }
    }

    const groupArray: GroupedLevels[] = [];
    for (const group of Object.keys(groups)) {
      const module = levels.find(level => level.module_name === group);
      groupArray.push({
        module_id: module.module_id,
        module_name: module.module_name,
        display_name: module.display_name,
        levels: groups[group].map((level: IUserLevelClient) => ({
          level_id: level.level_id,
          level_text: level.level_text
        }))
      });
    }
    return groupArray;
  }

  public usernameUniquenessValidator() {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const sameInUse = this.isEditing ? this.previousUsername.trim().toLowerCase() === control.value.trim().toLowerCase() : false;

      if (this.isEditing && sameInUse) return of(null);

      return timer(this.debounceTime).pipe(
        switchMap(() =>
          this.userService.searchUsersByFieldIgnoreCustomer('username', control.value.trim()).pipe(
            map((result: User[]) => {
              if (!result.find(user => user.customer_id === this.oidcService.customerId) && !this.usingPrefilledInputs) {
                const isInAnotherEnvironment = result.filter(user => user.customer_id !== this.oidcService.customerId);
                this.overlayOrigin = this.usernameOrigin;
                this.autoFillUser = isInAnotherEnvironment.length > 0 ? isInAnotherEnvironment[0] : null;
              }
              return result.length > 0 ? { usernameTaken: { value: control.value.trim() } } : null;
            }),
            catchError(() => of(null))
          )
        )
      );
    };
  }

  public clearAutofill() {
    this.autoFillUser = null;
  }

  public autoFillForm(user: User) {
    // patch form with all values from the copied user except the user levels
    delete user.user_levels;
    // and username, to avoid retriggering the validator
    this.modalUserForm.patchValue({ ...user });

    // Clear autofill user details and validation errors
    this.usingPrefilledInputs = { ...this.autoFillUser };
    this.autoFillUser = null;
    this.modalUserForm.get('username').setErrors(null);
    this.modalUserForm.get('email').setErrors(null);
  }

  public emailUniquenessValidator() {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      const sameInUse = this.isEditing ? this.previousEmail.trim().toLowerCase() === control.value.trim().toLowerCase() : false;
      if (this.isEditing && sameInUse) return of(null);

      return timer(this.debounceTime).pipe(
        switchMap(() =>
          this.userService.searchUsersByFieldIgnoreCustomer('email', control.value.trim()).pipe(
            map(result => {
              if (!result.find(user => user.customer_id === this.oidcService.customerId) && !this.usingPrefilledInputs) {
                const isInAnotherEnvironment = result.filter(user => user.customer_id !== this.oidcService.customerId);
                this.overlayOrigin = this.emailOrigin;
                this.autoFillUser = isInAnotherEnvironment.length > 0 ? isInAnotherEnvironment[0] : null;
              }
              return result.length > 0 ? { emailTaken: { value: control.value.trim() } } : null;
            }),
            catchError(() => of(null))
          )
        )
      );
    };
  }

  public getUserLevel = (levelName: string) => this.userService.getUserLevelClientByName(levelName);

  public userLevelSelected(event) {
    setTimeout(() => {
      if (event.isUserInput) {
        const groupName = event.source.value.module_name;
        const levelName = event.source.value.level_text;
        const selected = event.source.selected;
        const userLevelClient: IUserLevelClient = this.userService.getUserLevelClientByName(levelName);

        // Check if current selection performed in User Manager group
        const isUserManagerGroup = groupName.toLowerCase() === EmpyreanApplicationName.UserManager.toLowerCase();
        let selectedUserManagerLevelClients: IUserLevelClient[] = [];

        // Store currently selected levels for User Manager Group for modified user
        if (isUserManagerGroup) {
          selectedUserManagerLevelClients = this.selectedUserLevels.filter(ul => ul.module_name.toLowerCase() === groupName.toLowerCase());
        }

        // Allow one selection for each group
        this.selectedUserLevels = [...this.selectedUserLevels.filter(ul => ul.module_name.toLowerCase() !== groupName.toLowerCase())];

        // Selected Level
        if (selected && !isUserManagerGroup) {
          this.selectedUserLevels = [...this.selectedUserLevels, userLevelClient];
        } else if (selected && isUserManagerGroup) {
          // Allow multiple selection for User Manager group
          this.selectedUserLevels = [...this.selectedUserLevels, ...selectedUserManagerLevelClients, userLevelClient];
        }

        // Unselected Level
        if (!selected && isUserManagerGroup) {
          // For UserManager Group: Locate unselected record and remove it from levels keeping invisible level if any
          const indexOfUnselectedLevel = selectedUserManagerLevelClients.indexOf(userLevelClient);
          selectedUserManagerLevelClients.splice(indexOfUnselectedLevel, 1);
          this.selectedUserLevels = [...this.selectedUserLevels, ...selectedUserManagerLevelClients];
        }

        this.isBiGroupDisabled = !this.selectedUserLevels.filter(ul => ul.module_id !== EMPYREAN_BI_MODULE_ID).some(ul => ul.is_bi_enabled);
        if (this.isBiGroupDisabled) {
          this.selectedUserLevels = [...this.selectedUserLevels.filter(ul => ul.module_id !== EMPYREAN_BI_MODULE_ID)];
        }

        this.modalUserForm.patchValue({ user_levels: this.selectedUserLevels }, { emitEvent: false });
      }
    });
  }

  public isGroupDisabled = (group: IUserLevelClient) => group.module_id === EMPYREAN_BI_MODULE_ID && this.isBiGroupDisabled;

  public getBiTooltip() {
    if (!this.isBiGroupDisabled) {
      return null;
    }

    const clientNames = [...this.biEnabledClients.keys()].filter(key => this.biEnabledClients.get(key));

    return `To enable BI Reports, user must have a level in ${clientNames.length > 1 ? ' at least one of:\n' : ''}${enumerateString(
      clientNames,
      'or'
    )}`;
  }

  public resetForm() {
    this.usingPrefilledInputs = null;
    this.overlayOrigin = null;
    this.initForm();
  }
}
