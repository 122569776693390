import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalSettingsComponent } from '@components/global-settings/global-settings.component';
import { environment } from '@environments/environment';
import { AppConfigModules } from '@models/appconfig';
import { EmpyreanApplicationName, isUserLevelForApplication } from '@models/user-level';
import { AppConfigProvider } from '@root/app/app.config.provider';
import { OpenIdConnectService } from '@services/auth/open-id-connect.service';
import { toasterConfig } from '@shared/toaster-config';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('quickLinks', { static: false }) quickLinks: ElementRef;
  @ViewChild('userPanel', { static: false }) userPanel: ElementRef;

  public get EmpyreanApplicationName() {
    return EmpyreanApplicationName;
  }

  private onDestroy$ = new Subject();

  public isProduction = environment.production;
  public appVersion;
  public dialogLoading = false;
  public isSaving = false;
  public isDiscarding = false;
  public quickLinksOpen = false;
  public userAreaOpen = false;
  public toasterConfig = toasterConfig;
  public isUserAvailable = false;

  public moduleUrls: AppConfigModules;

  public isUrlAvailable(moduleName: EmpyreanApplicationName): boolean {
    return this.moduleUrls.hasOwnProperty(moduleName) && this.moduleUrls[moduleName] !== '';
  }

  public isApplicationAvailableToUser(moduleName: EmpyreanApplicationName): boolean {
    return isUserLevelForApplication(moduleName, this.openIdConnectService.userLevelList);
  }

  public isApplicationSwitcherEnabled(moduleName: EmpyreanApplicationName): boolean {
    return this.isApplicationAvailableToUser(moduleName) && this.isUrlAvailable(moduleName);
  }

  public applicationSwitcherTooltip(moduleName: EmpyreanApplicationName): string | null {
    if (!this.isUrlAvailable(moduleName)) {
      return 'This module is not available, please contact your Empyrean representative for more information.';
    }
    if (!this.isApplicationAvailableToUser(moduleName)) {
      return 'You are not authorized to access this module.';
    }

    return null;
  }

  constructor(public openIdConnectService: OpenIdConnectService, private appConfig: AppConfigProvider, public dialog: MatDialog) {
    this.appConfig.environment.subscribe(env => {
      this.appVersion = env.VERSION.BUILDNUMBER;
      this.moduleUrls = env.MODULES;
    });

    this.openIdConnectService.userLoaded$.subscribe(res => {
      this.isUserAvailable = this.openIdConnectService.userAvailable;
    });
  }

  ngOnInit() {
    this.hideDropdownOnClickOutside();
  }

  ngOnDestroy() {
    this.onDestroy$.next(takeUntil);
    this.onDestroy$.complete();
  }

  private hideDropdownOnClickOutside() {
    const outsideClickListener = event => {
      const inside = this.quickLinks.nativeElement.contains(event.target) || this.userPanel.nativeElement.contains(event.target);
      if (!inside) {
        this.closeAllDropdowns();
      }
    };

    document.addEventListener('click', outsideClickListener);
  }

  public closeAllDropdowns() {
    this.quickLinksOpen = false;
    this.userAreaOpen = false;
  }

  public toggleUserArea() {
    this.userAreaOpen = !this.userAreaOpen;
    if (this.userAreaOpen) {
      this.quickLinksOpen = false;
    }
  }

  public toggleQuickLinks() {
    this.quickLinksOpen = !this.quickLinksOpen;
    if (this.quickLinksOpen) {
      this.userAreaOpen = false;
    }
  }

  public openSettingsDialog() {
    this.dialog.open(GlobalSettingsComponent);
    this.closeAllDropdowns();
  }
}
