import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigProvider } from '@app/app.config.provider';
import {
  EmpyreanApplicationName,
  getUserLevelForApplication,
  isUserLevelForApplication,
  IUserLevel,
  UserLevel
} from '@models/user-level';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { InterruptOnNetworkCall } from '@services/auth/interrupt-idle-on-network-call';
import { GlobalParamsService } from '@services/global-params/global-params.service';
import { isEmpty } from '@shared/arrays';
import { User, UserManager } from 'oidc-client';
import { ReplaySubject } from 'rxjs';
import Swal, { SweetAlertOptions } from 'sweetalert2';

const ENABLE_DEBUGGING = false;

@Injectable({ providedIn: 'root' })
export class OpenIdConnectService {
  private userManager: UserManager;
  private currentUser: User;
  private countdownTimer: number;
  private popup;

  userLoaded$ = new ReplaySubject<boolean>(1);

  get userAvailable(): boolean {
    return Boolean(this.currentUser);
  }

  get user(): User {
    return this.currentUser;
  }

  get userId(): number {
    if (this.userAvailable) {
      return Number(this.currentUser.profile.sub);
    } else {
      this.triggerSignIn();
    }
  }

  get customerId(): number {
    const customer_id = Number(this.currentUser.profile.Custaim);
    if (!isNaN(customer_id) && typeof customer_id === 'number') {
      return customer_id as number;
    } else {
      console.warn('customer_id not found for current user');
      return null;
    }
  }

  private getManagerUserLevelFromProfile(profile): IUserLevel {
    const userLevels: IUserLevel[] = JSON.parse(profile.userlevels);
    return userLevels.find(level => [UserLevel.SystemAdministrator, UserLevel.HRSystemAdministrator].includes(level.level_id));
  }

  get userLevelList(): IUserLevel[] {
    if (this.userAvailable && this.currentUser.profile.userlevels) {
      return JSON.parse(this.currentUser.profile.userlevels);
    } else {
      return [{ level_id: -1, level_text: 'User level not found' }];
    }
  }

  get userLevel(): IUserLevel {
    if (
      this.userAvailable &&
      this.currentUser.profile.userlevels &&
      isUserLevelForApplication(EmpyreanApplicationName.UserManager, JSON.parse(this.currentUser.profile.userlevels))
    ) {
      const lvl = getUserLevelForApplication(EmpyreanApplicationName.UserManager, this.currentUser.profile.userlevels);
      return lvl;
    } else {
      return { level_id: -1, level_text: 'User level not found' };
    }
  }

  constructor(
    private router: Router,
    private idle: Idle,
    private params: GlobalParamsService,
    private appConfig: AppConfigProvider,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.appConfig.environment.subscribe(env => {
      let oidcSettings = env.openIdConnectSettings;

      let { host } = window.location;
      const allowedHost = env.ALLOWED_URLS.find((url: string) => host.includes(url));

      const fragments = host.split(`${allowedHost}`);

      if (allowedHost && fragments && fragments.length > 0 && !isEmpty(fragments[0])) {
        //
        let idpFragment = fragments[0];
        if (idpFragment.indexOf('.') === idpFragment.length - 1) {
          idpFragment = idpFragment.substring(0, idpFragment.indexOf('.'));
        }
        host = host.replace(/\/\s*$/, ''); // removes backlash at the end, if exists
        oidcSettings = {
          ...env.openIdConnectSettings,
          acr_values: `idp:${idpFragment}`,
          silent_redirect_uri: `https://${host}/redirect-silentrenew`,
          redirect_uri: `https://${host}/signin-oidc`,
          post_logout_redirect_uri: `https://${host}`
        };
      }

      this.userManager = new UserManager(oidcSettings);

      this.userManager.clearStaleState();
      this.userManager.events.addUserLoaded(user => {
        if (ENABLE_DEBUGGING) {
          console.log('User loaded ' + new Date(), this.currentUser, { user });
        }

        const incomingUserLevel = this.getManagerUserLevelFromProfile(user.profile);
        try {
          if (this.userLevel && incomingUserLevel && this.userLevel.level_id !== incomingUserLevel.level_id) {
            this.currentUser = user;
            this.idle.watch();
            this.userManager.startSilentRenew();
            this.userLoaded$.next(true);

            // Obtain global params from database and update the max idle time if it's not the default
            // this.params.getParams().toPromise();
          } else {
            if (this.getManagerUserLevelFromProfile(user.profile)) {
              this.currentUser = user;
              this.idle.watch();
              this.userManager.startSilentRenew();
            } else {
              if (ENABLE_DEBUGGING) console.error('OpenIdConnectService -> No liquidity user found in token');
              this.document.location.href = '/401';
            }
          }
        } catch (err) {
          if (ENABLE_DEBUGGING) console.error('OpenIdConnectService -> Unable to load token', err);
          this.document.location.href = '/401';
        }
      });

      this.userManager.events.addUserUnloaded(() => {
        // if (!environment.production) {
        //   console.log('User unloaded', e);
        // }
        this.currentUser = null;
        this.userLoaded$.next(false);
      });
    });
    // ***********
    // OIDC Events
    // ***********

    // ***********
    // Idle Config
    // ***********

    const TIMEOUT_TIMER = 30; // 30s till timeout
    const DEFAULT_IDLE_TIMER = 5 * 60; // 5 min idle
    let idleTimer = DEFAULT_IDLE_TIMER;

    // For debugging purposes

    // sets an idle timeout of DEFAULT_IDLE_TIMER seconds
    this.idle.setIdle(idleTimer);

    this.params.maxIdleTime.subscribe(time => {
      // Refresh the idle time if the database is different
      idleTimer = time * 60;

      // idleTimer = DEFAULT_IDLE_TIMER; // Uncomment for debugging

      // Stops the Idle timer if it's running, update it with the new value, then start again if User is logged in
      if (this.idle.isRunning()) {
        this.idle.stop();
      }
      this.idle.setIdle(idleTimer);
      if (this.userAvailable) {
        this.idle.watch();
      }
    });

    // sets a timeout period of TIMEOUT_TIMER seconds.
    // We are not, however, using the timeout implementation of the library.
    // After the TimeoutStarts event happen, we force the user to click the notification otherwise they will time out
    this.idle.setTimeout(TIMEOUT_TIMER);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts([...DEFAULT_INTERRUPTSOURCES, new InterruptOnNetworkCall()]);

    // ***********
    // Idle Events
    // ***********
    this.idle.onIdleEnd.subscribe(() => {
      if (ENABLE_DEBUGGING) console.log('No longer idle.');
    });

    this.idle.onTimeout.subscribe(() => {
      this.logout();
    });

    this.idle.onIdleStart.subscribe(() => {
      let timerInterval;
      if (!this.popup && this.userAvailable) {
        this.popup = Swal.fire({
          title: "You're about to be signed out",
          html:
            '<p>For security reasons, your connection will time out after you’ve been inactive for a while. ' +
            ' Click the button to stay signed in. <span id="timeoutAmt"></span></p>',
          timer: (TIMEOUT_TIMER + 1) * 1000,
          confirmButtonText: 'Stay Signed In',
          allowOutsideClick: false,
          icon: 'warning',
          // This creates the interval that will handle the countdown on the SWAL element
          didOpen: () => {
            this.countdownTimer = TIMEOUT_TIMER * 10;

            timerInterval = setInterval(() => {
              this.countdownTimer--;

              Swal.getHtmlContainer().querySelector('#timeoutAmt').textContent =
                this.countdownTimer > 0 ? `Remaining time: ${Math.floor(this.countdownTimer / 10)} s.` : '';
              if (this.countdownTimer <= 0) {
                clearInterval(timerInterval);
                this.logout();
              }
            }, 100);
          },
          willClose: () => {
            this.countdownTimer = TIMEOUT_TIMER;
            this.popup = null;
            clearInterval(timerInterval);
          },
        });
      }
    });
    this.idle.onTimeoutWarning.subscribe(countdown => {
      if (ENABLE_DEBUGGING) console.log('You will time out in ' + countdown + ' seconds!');
      if (!this.userAvailable) {
        if (ENABLE_DEBUGGING) console.warn('User not available when timing out');
        this.idle.unwatch();
        this.document.location.href = '/401';
      }
    });
  }

  /**
   * Triggers the OIDC routine to send an user to the OIDC endpoint to retrieve the token
   */
  triggerSignIn() {
    this.userManager
      .signinRedirect()
      .then(() => {
        if (ENABLE_DEBUGGING) {
          console.log('Redirection to signin triggered.');
        }
      })
      .catch(err => {
        if (ENABLE_DEBUGGING) console.error('Unhandled error when triggering login in', err);
        this.router.navigate(['/error']);
      });
  }

  /**
   * Triggers the OIDC routine to store the token in the session store and in memory
   */
  handleCallBack() {
    this.userManager
      .signinRedirectCallback()
      .then(user => {
        this.currentUser = user;
        if (ENABLE_DEBUGGING) {
          console.log('Callback after signin triggered.', user);
        }
      })
      .catch(err => {
        if (ENABLE_DEBUGGING) console.error('OpenIdConnectService -> handleCallBack ->', err, typeof err);
        if (err.toString().includes('Error: No matching state found in storage')) {
          this.triggerSignIn();
        }
      });
  }

  handleSilentCallback() {
    this.userManager
      .signinSilentCallback()
      .then(user => {
        // this.currentUser = user;
        // this.permissionService.loadPermissionsIntoApp(this.userId);
        if (ENABLE_DEBUGGING) {
          console.log('Callback after silent signin handled.', user);
          // We may need this
          // https://stackoverflow.com/questions/50416649/no-user-in-signinsilentcallback-using-identityserver-and-oidc-client-of-javascri
        }
      })
      .catch(err => {
        if (ENABLE_DEBUGGING) console.error('Unhandled error when silently triggering login in', err);
        this.router.navigate(['/error']);
      });
  }

  /**
   * Triggers the SingOut process for the OIDC library
   */
  triggerSignOut() {
    this.userManager
      .signoutRedirect()
      .then(res => {
        if (ENABLE_DEBUGGING) {
          console.log('Redirection to signout triggered.', res);
        }
      })
      .catch(err => {
        if (ENABLE_DEBUGGING) console.error('Unhandled error when signing out', err);
      })
      .finally(() => this.removeUserFromStorage());
  }

  removeUserFromStorage() {
    this.userManager.removeUser();
    this.userManager.revokeAccessToken();
  }

  /**
   * Routine that will be executed after Idle times the user out
   * It removes the popup if still active, then creates a call to the OIDC endpoint to remove the user
   * from the Identity Server and the frontend.
   * Then, it will stop all tracking services and navigate the user to the Timeout page
   */
  private logout() {
    if (this.popup) {
      this.popup.close();
    }

    try {
      this.userManager
        .createSignoutRequest({ id_token_hint: this.user.id_token, extraQueryParams: { reason: 'timeout' } })
        .then(signout_request => {
          this.document.location.href = signout_request.url;
        });
    } catch (err) {
      this.triggerSignOut();
    } finally {
      // This code should never execute. The try/catch should redirect to identity server in any case
      this.document.location.href = '/timeout'; // Bypasses angular router
      this.idle.stop();
      this.userManager.removeUser();
    }
  }
}
