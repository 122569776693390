import { pipe, range, timer, zip } from 'rxjs';
import { retryWhen, map, mergeMap } from 'rxjs/operators';

/**
 * Exponential backoff pipe
 * https://angular.io/guide/practical-observable-usage#exponential-backoff
 * @param maxTries Maximum number of tries before failing. Default set as 3
 * @param msBetweenTries Time between each retry in ms. Default set as 250ms
 * @example return this.http.get(url).pipe(backoff(3, 250));
 */
export const backoff = (maxTries: number = 3, msBetweenTries: number = 250) => {
  return pipe(
    retryWhen(attempts =>
      zip(range(1, maxTries), attempts).pipe(
        map(([i]) => i * i),
        mergeMap(i => timer(i * msBetweenTries))
      )
    )
  );
};
