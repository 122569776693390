import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationSwitcherComponent } from './application-switcher.component';
import { ApplicationSwitcherItemComponent } from './application-switcher-item/application-switcher-item.component';

@NgModule({
  declarations: [ApplicationSwitcherComponent, ApplicationSwitcherItemComponent],
  imports: [CommonModule],
  exports: [ApplicationSwitcherComponent],
})
export class ApplicationSwitcherModule {}
