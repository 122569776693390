import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddUserDialogComponent } from '@components/add-user-dialog/add-user-dialog.component';
import { EmpyreanApplicationName, IUserLevelClient, UserLevel, UserLevelApplicationLinks } from '@interfaces/user-level';
import { User } from '@models/user';
import { ActiveUsersToggleFilter, UserListFilter } from '@models/user-list';
import { UserStatus } from '@models/user-status';
import { OpenIdConnectService } from '@services/auth/open-id-connect.service';
import { UserService } from '@services/user/user.service';
import { openDialogConfirmation } from '@shared/swal-dialogs';
import { BehaviorSubject, combineLatest, forkJoin, Observable } from 'rxjs';
import { defaultIfEmpty, map } from 'rxjs/operators';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js'
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { ToastService } from '@services/toast-service/toast.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  public fetching = false;
  public tableFilter$ = new BehaviorSubject(new UserListFilter());
  public processingUserId: number = null;
  public userList$: Observable<User[]>;
  public userLevelList: IUserLevelClient[];
  public selectedToggleFilter: ActiveUsersToggleFilter;
  private currentUserId: number;
  private currentUserLevelList: UserLevel[];
  private excelfileName = 'UsersListExport.xlsx';
  private userList = [];
  userListSubscription: Subscription | undefined;


  public isCurrentUser(user: User) {
    return this.currentUserId === user.user_id;
  }

  public isActiveUser(user: User) {
    return user.record_status.toLowerCase() === UserStatus.A.toLowerCase();
  }

  // Expose enum to template
  public UserStatus = UserStatus;

  constructor(
    private readonly userService: UserService,
    private readonly auth: OpenIdConnectService,
    public dialog: MatDialog,
    private toastService: ToastService
  ) { }

  ngOnInit() {
    this.selectedToggleFilter = ActiveUsersToggleFilter.A;
    const userList$ = this.userService.userList$.pipe(defaultIfEmpty([] as User[]));
    this.userList$ = combineLatest(userList$, this.tableFilter$).pipe(
      map(([users, userListFilters]) => {
        this.currentUserId = this.auth.userId;
        this.currentUserLevelList = this.auth.userLevelList.map(level => level.level_id);

        users.forEach(user => {
          user.user_levels = user.user_levels.filter(ul => this.userService.userLevels[ul]);
        });

        return this.getFilteredUsers(users, userListFilters);
      })
    );

    this.fetching = true;

    forkJoin([this.userService.getUserLevels(), this.userService.getAllUsers()])
      .toPromise()
      .then(([userLevels, users]) => {
        this.userLevelList = userLevels;
      })
      .catch(err => {
        this.toastService.showToast(`Error loading User data`, 'Error', 'error', null);
        console.warn(err);
      })
      .finally(() => {
        this.fetching = false;
      });
  }

  public getUserLevelName(level_id: number) {
    return this.userService.getUserLevelNames([level_id]);
  }

  private getUsersByToggleFilter(users: User[], toggleFilter: ActiveUsersToggleFilter, recordStatusFilter: UserStatus) {
    switch (toggleFilter) {
      case ActiveUsersToggleFilter.All:
        return users;
      case ActiveUsersToggleFilter.Disabled:
        return users.filter(user => user.record_status.toLowerCase() !== UserStatus.D.toLowerCase() && !user.enabled);
      case ActiveUsersToggleFilter.D:
        return users.filter(user => user.record_status.toLowerCase() === recordStatusFilter.toLowerCase());
      default:
        return users.filter(user => user.record_status.toLowerCase() === recordStatusFilter.toLowerCase() && user.enabled);
    }
  }

  private getFilteredUsers(users: User[], userListFilters: UserListFilter) {
    let filteredUsers = this.getUsersByToggleFilter(users, this.selectedToggleFilter, userListFilters.record_status);

    if (userListFilters.all === '') {
      for (const propertyName of Object.keys(userListFilters)) {
        if (userListFilters[propertyName] !== '') {
          if (propertyName === 'user_level') {
            filteredUsers = filteredUsers.filter(user =>
              this.userService.getUserLevelNames(user.user_levels).toLowerCase().includes(userListFilters.user_level.toLowerCase())
            );
          } else if (!['all', 'record_status'].includes(propertyName)) {
            filteredUsers = filteredUsers.filter(user => user[propertyName].toLowerCase().includes(userListFilters[propertyName]));
          }
        }
      }
    } else {
      // Filter ALL
      filteredUsers = filteredUsers.filter(
        user =>
          user.username.toLowerCase().includes(userListFilters.all) ||
          user.first_name.toLowerCase().includes(userListFilters.all) ||
          user.last_name.toLowerCase().includes(userListFilters.all) ||
          user.email.toLowerCase().includes(userListFilters.all) ||
          this.userService.getUserLevelNames(user.user_levels).toLowerCase().includes(userListFilters.all.toLowerCase())
      );
    }
    return filteredUsers;
  }

  public editUserDialog(user: User) {
    this.dialog.open(AddUserDialogComponent, {
      data: { user, levels: this.userLevelList, currentUserLevelList: this.currentUserLevelList, isEditing: true },
      disableClose: true
    });
  }

  public addUserDialog() {
    this.dialog.open(AddUserDialogComponent, {
      data: { levels: this.userLevelList, currentUserLevelList: this.currentUserLevelList },
      disableClose: true
    });
  }

  public exportToExcel() {

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('User List');

    const columns = [
      { header: 'Username', key: 'Username' },
      { header: 'First Name', key: 'First Name' },
      { header: 'Last Name', key: 'Last Name' },
      { header: 'Email', key: 'Email' },
      { header: 'User Levels', key: 'User Levels' },
      { header: 'Is Enabled', key: 'Is Enabled' },
      { header: 'Status', key: 'Status' },
    ];

    worksheet.columns = columns;

    this.userListSubscription = this.userList$.subscribe(users => {
      this.userList = users.map(user => ({
        "Username": user.username,
        "First Name": user.first_name,
        "Last Name": user.last_name,
        "Email": user.email,
        "User Levels": this.userService.getUserLevelNames(user.user_levels),
        "Is Enabled": user.enabled.toString(),
        "Status": user.record_status
      }));
    });

    worksheet.addRows(this.userList);

    // Set the font for all cells in the worksheet to "Segoe UI"
    worksheet.eachRow(row => {
      row.eachCell({ includeEmpty: true }, cell => {
        cell.font = { name: 'Segoe UI' };
      });
    });

    // Make the first row bold, apply a background color, and change the font color
    const firstRow = worksheet.getRow(1);
    firstRow.eachCell({ includeEmpty: true }, cell => {
      cell.font = { bold: true, color: { argb: 'FFFFFF' }, name: 'Segoe UI', size: 12 };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '37475d' } // Background color
      };
    });

    // Autofit column widths
    worksheet.columns.forEach(column => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, cell => {
        const columnLength = cell.value ? cell.value.toString().length : 10;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength + 2; // Add some padding
    });

    // Create a buffer for the Excel file
    workbook.xlsx.writeBuffer().then(data => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, this.excelfileName); // You can use FileSaver or another method to save the file
    });

    if (this.userListSubscription) {
      this.userListSubscription.unsubscribe();
    }
  }

  public onChangeFilter(event: Event, field: string = '') {
    const value = (event.target as HTMLInputElement).value || '';
    const currentFilter = this.tableFilter$.getValue();
    currentFilter[field] = value.toLowerCase();
    this.tableFilter$.next(currentFilter);
  }

  private async deleteUser(user: User) {
    this.processingUserId = user.user_id;

    try {
      await this.userService.deleteUser(user).toPromise();
      user.record_status = UserStatus.D;
      this.onToggleChange(this.selectedToggleFilter, 'record_status');
      this.showUndoToast(user);
    } catch (err) {
      this.toastService.showToast(`Error deleting user`, "Error", 'error', null);
      console.warn(err);
    } finally {
      this.processingUserId = null;
    }
  }

  public onDeleteUserClicked(user: User) {
    if (!this.isCurrentUser(user)) {
      const title = 'Confirm Deletion';
      const text = `Deleting the user ${user.username} will result in the removal of all the changes by the user that have not been saved yet. Do you wish to Proceed?`;
      openDialogConfirmation(title, text).then(result => {
        if (result.value) {
          this.deleteUser(user);
        }
      });
    }
  }

  public onUndoDeleteUserClicked(user_id: number) {
    this.userService.getUserById(user_id).subscribe((user: User) => {
      if (user) {
        this.undoAction(user, 'deleted');
      }
    });
  }

  private async undeleteUser(user: User) {
    this.processingUserId = user.user_id;

    try {
      const undeletedUser = await this.userService.undeleteUser(user).toPromise();
      user.record_status = UserStatus.A;
      this.onToggleChange(this.selectedToggleFilter, 'record_status');
      this.toastService.showToast(`${user.username} has been undeleted`, 'Success', 'success', null);
    } catch (err) {
      this.toastService.showToast(`Error undeleting user`, 'Error', 'error', null);
      console.warn(err);
    } finally {
      this.processingUserId = null;
    }
  }

  public onUndeleteUserClicked(user: User) {
    this.undeleteUser(user);
  }

  private undoAction(user: User, actionTypeToUndo: 'deleted' | 'undeleted') {
    if (actionTypeToUndo === 'deleted') {
      this.undeleteUser(user);
    } else if (actionTypeToUndo === 'undeleted') {
      this.deleteUser(user);
    }
  }

  public onToggleChange(value: ActiveUsersToggleFilter, field: string) {
    this.selectedToggleFilter = value;
    const currentFilter = this.tableFilter$.getValue();
    currentFilter[field] = value;
    this.tableFilter$.next(currentFilter);
  }

  public showSyncStatus = (level_id: number, user: User): boolean =>
    (level_id === 8001 || level_id === 8002) && user.is_bi_user_sync !== null;

  private showUndoToast(user: User) {
    let body: string;
    const type = 'success';
    body = `
            <div style="width: 100%;">
              <div>
                <span style="font-size: 1rem; font-weight: bold;">${user.username}</span>
                has been deleted
              </div>
              <div>
                <button type="button" id="btn-action-undo-delete" class="btn_undo" style="
                  background-color: white;
                  font-weight: bold;
                  border: none;
                  color: #51a351;
                  padding: 5px 10px;
                  cursor: pointer;
                  border-radius: 4px;
                  float: right;
                  margin-top: 10px;">
                  Undo
                </button>
              </div>
            </div>
            `;
    // Show the toast using ngx-toastr

    this.toastService.showToast(body, "", "success", { timeOut: 5000, closeButton: true, tapToDismiss: false, disableTimeOut: false, enableHtml: true });
  }

  public isLevelVisible(userLevel: UserLevel): boolean {
    const isHRAdministrator = this.currentUserLevelList.includes(UserLevel.HRSystemAdministrator);
    const isSystemAdministrator = this.currentUserLevelList.includes(UserLevel.SystemAdministrator);
    const isPayrollLevel = UserLevelApplicationLinks[EmpyreanApplicationName.Payroll].includes(userLevel);

    // HR Admins cannot see or change user levels outside Payroll, Only HR Admins can see or change Payroll Levels
    if (
      (userLevel === UserLevel.HRSystemAdministrator && !isHRAdministrator) ||
      (isPayrollLevel && !isHRAdministrator) ||
      (!isPayrollLevel && userLevel !== UserLevel.HRSystemAdministrator && !isSystemAdministrator)
    ) {
      return true;
    }

    return false;
  }
}
