import Swal from 'sweetalert2';

export const openDialogConfirmation = (titleDialog: string, textDialog: string) => {
  return Swal.fire({
    title: titleDialog,
    text: textDialog,
    showCancelButton: true,
    confirmButtonText: 'Proceed',
    cancelButtonText: 'Cancel',
    reverseButtons: true,
    allowEscapeKey: true,
  });
};
