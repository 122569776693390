import { UserStatus } from './user-status';

export class User {
  customer_id: number = -1;
  date_time_created: string = '';
  date_time_modified: string = '';
  email: string = '';
  enabled: boolean = true;
  first_name: string = '';
  form_id: string = '';
  language: string = 'en';
  last_name: string = '';
  record_status: UserStatus = UserStatus.A;
  timezone: string = '';
  user_id_created: string = '';
  user_id_modified: string = '';
  user_id: number = -1;
  bi_user_id: string;
  is_bi_user_sync: boolean;
  user_levels: number[] = [];
  user_status_description?: string = '';
  username: string = '';
}
