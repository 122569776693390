import { UserQuery } from '@models/UserQuery';
import { User } from '@models/user';

export class UserRequestModel {
  public Model: User = new User();
  public id: number = -1;
  public Query: UserQuery = {
    fieldName: '',
    fieldValue: '',
  } as UserQuery;
}
