// Toaster Setup

import { ToasterConfig } from 'angular2-toaster';

// Used in dialog.component.ts
export const toasterConfig: ToasterConfig = new ToasterConfig({
  positionClass: 'toast-top-right', // toast-top-full-width
  showCloseButton: { warning: true, error: true, info: true, success: true },
  tapToDismiss: true,
  // timeout: { warning: 4000, error: 0, info: 2000, success: 2000 },
  timeout: 5000,
  newestOnTop: true,
  animation: 'fade', // 'fade', 'flyLeft', 'flyRight', 'slideDown', 'slideUp'
  mouseoverTimerStop: true,
  limit: 5,
});
