import { AfterViewInit, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AppConfigModules } from '@models/appconfig';
import { EmpyreanApplicationName, isUserLevelForApplication } from '@models/user-level';
import { AppConfigProvider } from '@root/app/app.config.provider';
import { ApplicationUrlService } from '@services/application-switcher/application-url.service';
import { OpenIdConnectService } from '@services/auth/open-id-connect.service';
@Component({
  selector: 'app-application-switcher',
  templateUrl: './application-switcher.component.html',
  styleUrls: ['./application-switcher.component.scss']
})
export class ApplicationSwitcherComponent implements OnInit, AfterViewInit {
  public moduleUrls: AppConfigModules;

  public get EmpyreanApplicationName() {
    return EmpyreanApplicationName;
  }

  constructor(
    private readonly appConfig: AppConfigProvider,
    public openIdConnectService: OpenIdConnectService,
    private readonly urlService: ApplicationUrlService,
    private readonly changeDetector: ChangeDetectorRef
  ) {
    this.appConfig.environment.subscribe(env => {
      this.moduleUrls = env.MODULES;
    });
  }
  ngOnInit(): void {
    this.openIdConnectService.userLoaded$.subscribe(user => {
      if (user) {
        this.retrieveAppUrls();
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.openIdConnectService.userAvailable) {
      this.retrieveAppUrls();
    }
  }

  private retrieveAppUrls() {
    this.urlService.getUrls().subscribe(urls => {
      let hasChanges = false;
      for (const url of urls) {
        if (url.moduleUrl && this.moduleUrls.hasOwnProperty(url.name.toUpperCase())) {
          this.moduleUrls[url.name.toUpperCase()] = url.moduleUrl;
          hasChanges = true;
        }
      }
      if (hasChanges) {
        this.changeDetector.detectChanges();
        // console.log(this.moduleUrls);
      }
    });
  }

  public isUrlAvailable(moduleName: EmpyreanApplicationName): boolean {
    return this.moduleUrls.hasOwnProperty(moduleName) && this.moduleUrls[moduleName] !== '';
  }

  public isApplicationAvailableToUser(moduleName: EmpyreanApplicationName): boolean {
    return isUserLevelForApplication(moduleName, this.openIdConnectService.userLevelList);
  }

  public isApplicationSwitcherEnabled(moduleName: EmpyreanApplicationName): boolean {
    return this.isApplicationAvailableToUser(moduleName) && this.isUrlAvailable(moduleName);
  }

  public applicationSwitcherTooltip(moduleName: EmpyreanApplicationName): string | null {
    if (!this.isUrlAvailable(moduleName)) {
      return 'This module is not available, please contact your Empyrean representative for more information.';
    }
    if (!this.isApplicationAvailableToUser(moduleName)) {
      return 'You are not authorized to access this module.';
    }

    return null;
  }
}
