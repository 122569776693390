import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@services/settings/settings.service';

@Component({
  selector: 'app-network-error',
  templateUrl: './network-error.component.html',
  styleUrls: ['./network-error.component.scss']
})
export class NetworkErrorComponent implements OnInit {
  constructor(public settings: SettingsService) {}

  ngOnInit() {}
}
