import { MaintenanceComponent } from './maintenance/maintenance.component';
import { Error500Component } from './error500/error500.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Error404Component } from './error404/error404.component';
import { RouterModule } from '@angular/router';
import { NetworkErrorComponent } from './network-error/network-error.component';
import { Error401Component } from './error401/error401.component';
import { TimeoutComponent } from './timeout/timeout.component';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [
    Error500Component,
    Error404Component,
    MaintenanceComponent,
    NetworkErrorComponent,
    Error401Component,
    TimeoutComponent
  ]
})
export class ErrorPagesModule {}
