import { OpenIdConnectService } from '@services/auth/open-id-connect.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RequireAuthenticatedUserRouteGuardService implements CanActivate {
  constructor(private openIdConnectService: OpenIdConnectService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.openIdConnectService.userAvailable) {
      // console.error('Unable to obtain permissions for user', `Section : ${next.data.section}`);
      // window.location.href = '/401';
      return true;
    } else {
      this.openIdConnectService.triggerSignIn();
      return false;
    }
  }
}
