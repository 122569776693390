import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@services/settings/settings.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component implements OnInit {
  constructor(public settings: SettingsService, private _location: Location, private router: Router) {}

  ngOnInit() {}

  public goBack() {
    this.router.navigate(['']);
  }
}
