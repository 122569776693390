import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EmpyreanLoaderComponent } from './empyrean-loader.component';

@NgModule({
  declarations: [EmpyreanLoaderComponent],
  imports: [CommonModule],
  exports: [EmpyreanLoaderComponent]
})
export class EmpyreanLoaderModule {}
