import { OpenIdConnectService } from '@services/auth/open-id-connect.service';
import { Component, OnInit } from '@angular/core';
import { SettingsService } from '@services/settings/settings.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-error401',
  templateUrl: './error401.component.html',
  styleUrls: ['./error401.component.scss'],
})
export class Error401Component implements OnInit {
  constructor(
    public settings: SettingsService,
    private _location: Location,
    private router: Router,
    private auth: OpenIdConnectService
  ) {}

  ngOnInit() {}

  public goBack() {
    this.router.navigate(['/']);
  }

  public signin() {
    this.auth.triggerSignOut();
    // this.auth.removeUserFromStorage();
    this.auth.triggerSignIn();
  }
}
