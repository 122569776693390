import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DisableControlDirective } from '@components/add-user-dialog/disable-control-directive.directive';
import { GlobalSettingsComponent } from '@components/global-settings/global-settings.component';
import { ApplicationSwitcherModule } from '@core/application-switcher/application-switcher.module';
import { RedirectSilentRenewComponent } from '@core/auth/redirect-silent-renew/redirect-silent-renew.component';
import { SigninOidcComponent } from '@core/auth/signin-oidc/signin-oidc.component';
import { EmpyreanLoaderModule } from '@core/empyrean-loader/empyrean-loader.module';
import { ErrorPagesModule } from '@core/error-pages/error-pages.module';
import { FooterComponent } from '@core/footer/footer.component';
import { HeaderComponent } from '@core/header/header.component';
import { MaterialModule } from '@core/material.module';
import { NgIdleModule } from '@ng-idle/core';
import { httpInterceptorProviders } from '@services/http-interceptors';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfigProvider } from './app.config.provider';
import { AddUserDialogComponent } from './components/add-user-dialog/add-user-dialog.component';
import { UserListComponent } from './components/user-list/user-list.component';

export function appConfigProviderFactory(provider: AppConfigProvider) {
  return () => provider.load();
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    RedirectSilentRenewComponent,
    SigninOidcComponent,
    UserListComponent,
    GlobalSettingsComponent,
    AddUserDialogComponent,
    DisableControlDirective,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    EmpyreanLoaderModule,
    ErrorPagesModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    ReactiveFormsModule,
    ApplicationSwitcherModule,
    NgIdleModule.forRoot(),
    ToastrModule.forRoot(),
  ],
  providers: [
    AppConfigProvider,
    { provide: APP_INITIALIZER, useFactory: appConfigProviderFactory, deps: [AppConfigProvider], multi: true },
    httpInterceptorProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
