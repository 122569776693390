export const toTitleCase = words =>
  words
    .toLowerCase()
    .split(/[\s_]/)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

/**
 * Enumerate elements of an array, finishing with 'and' _lastItem_
 * @example
 * enumerateString(['January', 'February', 'March']) // 'January, February and March'
 * enumerateString(['Monday', 'Tuesday', 'Wednesday'], 'or') // 'Monday, Tuesday or Wednesday'
 */
export const enumerateString = (words: string[], conjunction = 'and') => {
  const lastWord = words.pop();
  return words.join(', ') + (words.length > 0 ? ` ${conjunction} ` : '') + lastWord;
};
