import { FormControl } from '@angular/forms';

// https://stackoverflow.com/questions/37066442/model-values-not-trim-automatically-in-angular-2

export class TrimFormControl extends FormControl {
  private _value!: string | null;
// @ts-ignore
  get value(): string | null {
    return this._value;
  }

  set value(value: string | null) {
    this._value = value ? value.trim() : value;
  }
}
