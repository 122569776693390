import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IGlobalParam } from '@interfaces/global-param';
import { AppConfigProvider } from '@root/app/app.config.provider';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class GlobalParamsService {
  private apiUrl;
  /**
   * Determines if the user can run simulations even if the Required Import flag is set to YES
   */
  public maxIdleTime = new BehaviorSubject<number>(5);

  constructor(private http: HttpClient, private appConfig: AppConfigProvider) {
    this.appConfig.environment.subscribe(env => {
      this.apiUrl = `${env.API.USERAPI}/api/settings`;
    });
  }

  /**
   * Obtains the GlobalParams from the database as a collection of IGlobalParams
   * @augments GlobalSettingsComponent
   * @emits ignoreImportWarning flag to the system, obtained from the GlobalParams. Defaults to false.
   */
  public getParams(): Observable<IGlobalParam[]> {
    return this.http.get<IGlobalParam[]>(this.apiUrl).pipe(
      shareReplay(1),
      map((params: IGlobalParam[]) => this.filterAndFormatParamsFromDB(params))
    );
  }

  private filterAndFormatParamsFromDB(params: IGlobalParam[]) {
    const filteredParams = params.filter(param => {
      param.Valid = true;
      param.data_type = 'NUMBER'; // default param type
      param.ReadOnly = false;

      if (param.group === 'USER' && param.name === 'MAX_IDLE_TIME') {
        this.maxIdleTime.next(+param.value);
        // This param is only shown to System Admins
        param.ValidationMessage = `Idle time must be at least 5 mins.`;
      }

      return true;
    });

    filteredParams
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .sort((a, b) => (a.group > b.group ? 1 : -1))
      .sort((a, b) => (a.ReadOnly > b.ReadOnly ? -1 : 1));

    return filteredParams;
  }

  public updateUserSettings(params: IGlobalParam[]) {
    const onlyModifiedParams = params
      .filter(p => p.Modified && p.Valid)
      .map(p => ({
        value: p.value,
        group: p.group,
        name: p.name,
      }));

    return this.http.post<IGlobalParam[]>(this.apiUrl, onlyModifiedParams).pipe(map(params => this.filterAndFormatParamsFromDB(params)));
  }
}
