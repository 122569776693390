import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserListComponent } from '@components/user-list/user-list.component';
import { RedirectSilentRenewComponent } from '@core/auth/redirect-silent-renew/redirect-silent-renew.component';
import { SigninOidcComponent } from '@core/auth/signin-oidc/signin-oidc.component';
import { Error401Component } from '@core/error-pages/error401/error401.component';
import { Error404Component } from '@core/error-pages/error404/error404.component';
import { NetworkErrorComponent } from '@core/error-pages/network-error/network-error.component';
import { TimeoutComponent } from '@core/error-pages/timeout/timeout.component';
import { RequireAuthenticatedUserRouteGuardService } from '@services/guards/auth/require-authenticated-user-route-guard.service';

const routes: Routes = [
  { path: '', redirectTo: '/user-management', pathMatch: 'full' },

  { path: 'index', redirectTo: '/user-management', pathMatch: 'full' },
  { path: 'signin-oidc', component: SigninOidcComponent },
  { path: 'redirect-silentrenew', component: RedirectSilentRenewComponent },
  {
    path: 'user-management',
    component: UserListComponent,
    canActivate: [RequireAuthenticatedUserRouteGuardService],
    data: {
      section: 'ManageUser',
    },
  },
  { path: 'error', component: NetworkErrorComponent },
  { path: 'timeout', component: TimeoutComponent },
  { path: '404', component: Error404Component },
  { path: '401', component: Error401Component },
  { path: '**', component: Error404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
