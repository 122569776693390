import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConfigProvider } from '@app/app.config.provider';
import { OpenIdConnectService } from '@services/auth/open-id-connect.service';
import { of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApplicationUrlService {
  constructor(
    private readonly http: HttpClient,
    private readonly appConfig: AppConfigProvider,
    private readonly oidc: OpenIdConnectService
  ) {}

  public getUrls() {
    return this.appConfig.environment.pipe(
      mergeMap(env => {
        return this.oidc.userAvailable ? this.http.get<IModuleUrl[]>(`${env.API.CUSTOMERCONFIG}/api/Module/Urls`) : of([] as IModuleUrl[]);
      }),
      map(env =>
        env.map(moduleUrl => ({ ...moduleUrl, name: moduleUrl.name !== 'SharedDimensions' ? moduleUrl.name : 'Dimensions' } as IModuleUrl))
      )
    );
  }
}

export interface IModuleUrl {
  id: number;
  name: string;
  moduleUrl: string;
}
