export interface IUserLevel {
  level_id: number;
  level_text: string;
}

export type GroupedLevels = {
  module_id: number;
  module_name: string;
  display_name: string;
  levels: IUserLevel;
};

export interface IUserLevelClient extends IUserLevel {
  client_id: number;
  client_name: string;
  is_bi_enabled: boolean;
  module_id: number;
  module_name: string;
  display_name: string;
}

export enum EmpyreanApplicationName {
  Liquidity = 'LIQUIDITY',
  Deposits = 'DEPOSITS',
  UserManager = 'USERMANAGER',
  Dimensions = 'DIMENSIONS',
  Planning = 'PLANNING',
  Payroll = 'PAYROLL',
  Profitability = 'PROFITABILITY'
}

export const EMPYREAN_BI_MODULE_ID = 7;

// /**
//  * Matches Identity's dbo.Levels
//  * ```sql
//  * ╔══════════╤═══════════════════════════════╤═══════════╗
//  * ║ level_id │ 'level_text'                  │ module_id ║
//  * ╟──────────┼───────────────────────────────┼───────────╢
//  * ║   1001   │ 'System Administrator'        │     3     ║
//  * ╟──────────┼───────────────────────────────┼───────────╢
//  * ║   1002   │ 'Liquidity Administrator'     │     1     ║
//  * ║   1003   │ 'Liquidity User'              │     1     ║
//  * ║   1004   │ 'Liquidity Reader'            │     1     ║
//  * ╟──────────┼───────────────────────────────┼───────────╢
//  * ║   2001   │ 'Deposits Administrator'      │     2     ║
//  * ║   2002   │ 'Deposits User'               │     2     ║
//  * ║   2003   │ 'Deposits Reader'             │     2     ║
//  * ╟──────────┼───────────────────────────────┼───────────╢
//  * ║   3001   │ 'Dataverse Administrator'    │     4     ║
//  * ║   3002   │ 'Dataverse Reader'           │     4     ║
//  * ╟──────────┼───────────────────────────────┼───────────╢
//  * ║   4001   │ 'Profitability Administrator' │     5     ║
//  * ║   4002   │ 'Profitability User'          │     5     ║
//  * ║   4003   │ 'Profitability Reader'        │     5     ║
//  * ╟──────────┼───────────────────────────────┼───────────╢
//  * ║   5001   │ 'Planning Administrator'      │     6     ║
//  * ║   5002   │ 'Planning User'               │     6     ║
//  * ║   5003   │ 'Planning Reader'             │     6     ║
//  * ║   5004   │ 'Planning Report Viewer'      │     6     ║
//  * ╟──────────┼───────────────────────────────┼───────────╢
//  * ║   8001   │ 'BI Report Creator'           │     7     ║
//  * ║   8002   │ 'BI Report Viewer'            │     7     ║
//  * ╚══════════╧═══════════════════════════════╧═══════════╝
//  * ```
//  */
export enum UserLevel {
  SystemAdministrator = 1001,

  LiquidityAdministrator = 1002,
  LiquidityUser = 1003,
  LiquidityReader = 1004,

  DepositsAdministrator = 2001,
  DepositsUser = 2002,
  DepositsReader = 2003,

  DataverseAdministrator = 3001,
  DataverseReader = 3002,

  ProfitabilityAdministrator = 4001,
  ProfitabilityUser = 4002,
  ProfitabilityReader = 4003,

  PlanningAdministrator = 5001,
  PlanningContributor = 5002, // equivalent to 'Planning User'
  PlanningReader = 5003,
  PlanningViewer = 5004,

  HRSystemAdministrator = 7000,
  PayrollAdministrator = 7001,
  PayrollContributor = 7002,
  PayrollReader = 7003,

  BiReportCreator = 8001,
  BiReportViewer = 8002
}

export const isUserLevelForApplication = (applicationName: EmpyreanApplicationName, levels: IUserLevel[]) => {
  return levels.some(level => UserLevelApplicationLinks[applicationName].includes(level.level_id));
};

export const getUserLevelForApplication = (applicationName: EmpyreanApplicationName, levels: IUserLevel[]) => {
  return levels.find(level => UserLevelApplicationLinks[applicationName].includes(level.level_id));
};

export const UserLevelApplicationLinks: { [key in EmpyreanApplicationName]: number[] } = {
  [EmpyreanApplicationName.UserManager]: [UserLevel.SystemAdministrator, UserLevel.HRSystemAdministrator],
  [EmpyreanApplicationName.Liquidity]: [UserLevel.LiquidityAdministrator, UserLevel.LiquidityUser, UserLevel.LiquidityReader],
  [EmpyreanApplicationName.Deposits]: [UserLevel.DepositsAdministrator, UserLevel.DepositsUser, UserLevel.DepositsReader],
  [EmpyreanApplicationName.Dimensions]: [UserLevel.DataverseAdministrator, UserLevel.DataverseReader],
  [EmpyreanApplicationName.Planning]: [UserLevel.PlanningAdministrator, UserLevel.PlanningContributor, UserLevel.PlanningReader],
  [EmpyreanApplicationName.Payroll]: [UserLevel.PayrollAdministrator, UserLevel.PayrollContributor, UserLevel.PayrollReader],
  [EmpyreanApplicationName.Profitability]: [
    UserLevel.ProfitabilityAdministrator,
    UserLevel.ProfitabilityReader,
    UserLevel.ProfitabilityUser
  ]
};

export interface IUserlevelRequest {
  user_level_id: number;
  user_id: number;
}

export interface IUserlevelResponse {
  success: boolean;
}
